import React from 'react';

import { Radio, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";



import global from '../lib/global';


export default function ModelDevices(shotConfig, handleShotConfig) {

  const ShotDeviceData = global.ShotDeviceData;
  const { t } = useTranslation();
  const { Title } = Typography;


  const onChangeDevice = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotDevice = e.target.value;
    // ShotDeviceData.forEach(element => {
    //   if(element.key===e.target.value){
    //     document.documentElement.style.setProperty('--shot-device-frame-width', element.frameWidth + 'px');
    //     document.documentElement.style.setProperty('--shot-device-frame-height', element.frameHeight + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-width', element.contentWidth + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-height', element.contentHeight + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-top', element.contentTop + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-left', element.contentLeft + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-radius', element.contentRadius + 'px');
    //     document.documentElement.style.setProperty('--shot-device-image', 'url("' + element.image + '")' );
    //     document.documentElement.style.setProperty('--shot-device-image-mockup', 'url("' + element.imageMockup + '")' );
    //   }
    // });
    handleShotConfig(_shotConfig)
  };


  const onChangeDeviceGlass = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotDeviceGlass = e.target.value;
    handleShotConfig(_shotConfig)
  };

  const frameDevices = (<>
    <Title level={4}>{t('menu.device.title', 'Device')}</Title>
    <Radio.Group onChange={onChangeDevice} value={shotConfig.shotDevice} >
      <Space direction="vertical">
        <Radio key='nodevice' value={'nodevice'}>{t('menu.device.nodevice', 'No Device')}</Radio>
        {new Array(ShotDeviceData.length).fill(ShotDeviceData).map((_, i) => {
          return <Radio key={ShotDeviceData[i].key} value={ShotDeviceData[i].key}>{t('menu.device.' + ShotDeviceData[i].key, ShotDeviceData[i].default)}</Radio>;
        })}
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.effect.glass.title', 'Glass')}</Title>
    <Radio.Group onChange={onChangeDeviceGlass} value={shotConfig.shotDeviceGlass}>
      <div>
        <Radio key='device_glass_hide' value={'0'}>{t('menu.effect.glass.hide', 'Hide')}</Radio>
        <Radio key='device_glass1' value={'1'}>{t('menu.effect.glass.glass1', 'Glass 1')}</Radio>
        <Radio key='device_glass2' value={'2'}>{t('menu.effect.glass.glass2', 'Glass 2')}</Radio>
        <Radio key='device_glass3' value={'3'}>{t('menu.effect.glass.glass3', 'Glass 3')}</Radio>
      </div>
    </Radio.Group>
  </>);
  return (frameDevices);
}