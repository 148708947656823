import React from 'react';

import { Radio, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";

import global from '../lib/global';

export default function ModelFonts(shotConfig, handleShotConfig) {

  const ShotFontCodeData = global.ShotFontCodeData;
  const ShotFontFunData = global.ShotFontFunData;
  const { t } = useTranslation();
  const { Title } = Typography;


  const onChangeFontFace = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotFontFace = e.target.value;
    //setShotConfig(_shotConfig)
    handleShotConfig(_shotConfig)
  };
  const onChangeTextSize = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotTextSize = e.target.value;
    //setShotConfig(_shotConfig)
    handleShotConfig(_shotConfig)
  };

  const onChangeTextAlign = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotTextAlign = e.target.value;
    //setShotConfig(_shotConfig)
    handleShotConfig(_shotConfig)
  };

  const frameFonts = (<>
    <Title level={4}>{t('menu.font.font_code.title', 'Font')}</Title>
    <Radio.Group onChange={onChangeFontFace} value={shotConfig.shotFontFace} >
      <Space direction="vertical">
        <Radio key='font_default' value={'font_default'}>{t('menu.font.font_code.font_default', 'Default Font')}</Radio>
        {new Array(ShotFontCodeData.length).fill(ShotFontCodeData).map((_, i) => {
          return <Radio key={ShotFontCodeData[i].key} value={ShotFontCodeData[i].key}>{t('menu.font.font_code.' + ShotFontCodeData[i].key, ShotFontCodeData[i].default)}</Radio>;
        })}
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.font.font_fun.title', 'Font')}</Title>
    <Radio.Group onChange={onChangeFontFace} value={shotConfig.shotFontFace} >
      <Space direction="vertical">
        {new Array(ShotFontFunData.length).fill(ShotFontFunData).map((_, i) => {
          return <Radio key={ShotFontFunData[i].key} value={ShotFontFunData[i].key}>{t('menu.font.font_fun.' + ShotFontFunData[i].key, ShotFontCodeData[i].default)}</Radio>;
        })}
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.font.text_size.title', 'Text Size')}</Title>
    <Radio.Group onChange={onChangeTextSize} value={shotConfig.shotTextSize} optionType="button" className='smallgrid'>
      <div>
        <Radio key='textsize1' value={'tiny'}>1</Radio>
        <Radio key='textsize2' value={'small'}>2</Radio>
        <Radio key='textsize3' value={'normal'}>3</Radio>
        <Radio key='textsize4' value={'big'}>4</Radio>
        <Radio key='textsize5' value={'mega'}>5</Radio>
      </div>
    </Radio.Group>
    <Title level={4}>{t('menu.font.text_align.title', 'Text Align')}</Title>
    <Radio.Group onChange={onChangeTextAlign} value={shotConfig.shotTextAlign} optionType="button" className='smallgrid'>
      <div>
        <Radio key='textalign1' value={'left'}>{t('menu.font.text_align.left', 'Left')}</Radio>
        <Radio key='textalign2' value={'center'}>{t('menu.font.text_align.center', 'Center')}</Radio>
        <Radio key='textalign3' value={'right'}>{t('menu.font.text_align.right', 'Right')}</Radio>
        <Radio key='textalign4' value={'title-center'}>{t('menu.font.text_align.title_center', 'Title Center')}</Radio>
      </div>
    </Radio.Group>
  </>);

  return (frameFonts);
}