import React from 'react';

import { Radio, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";

import global from '../lib/global';

export default function ModelMedias(shotConfig, handleShotConfig) {


  const ShotMediaData = global.ShotMediaData;
  const ShotScreenShotData = global.ShotScreenShotData;
  
  const { t } = useTranslation();
  const { Title } = Typography;

  const updateShotSize = global.updateShotSize;


  const onChangeMedia = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotMedia = e.target.value;
    _shotConfig = updateShotSize(_shotConfig);
    handleShotConfig(_shotConfig)
  };


  const onChangeAspect = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotAspect = e.target.value;
    _shotConfig = updateShotSize(_shotConfig);
    handleShotConfig(_shotConfig);
  };

  const frameMedias = (<>
    <Title level={4}>{t('menu.media.template.title', 'Template')}</Title>
    <Radio.Group onChange={onChangeMedia} value={shotConfig.shotMedia} >
      <Space direction="vertical">
        {new Array(ShotMediaData.length).fill(ShotMediaData).map((_, i) => {
          return <Radio key={ShotMediaData[i].key} value={ShotMediaData[i].key}>{t('menu.media.template.' + ShotMediaData[i].key, ShotMediaData[i].default)}</Radio>;
        })}
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.media.screenshot.title', 'ScreenShot')}</Title>
    <Radio.Group onChange={onChangeMedia} value={shotConfig.shotMedia} >
      <Space direction="vertical">
        {new Array(ShotScreenShotData.length).fill(ShotScreenShotData).map((_, i) => {
          return <Radio key={ShotScreenShotData[i].key} value={ShotScreenShotData[i].key}>{t('menu.media.screenshot.' + ShotScreenShotData[i].key, ShotScreenShotData[i].default)}</Radio>;
        })}
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.media.aspect.title', 'Aspect')}</Title>
    <Radio.Group onChange={onChangeAspect} value={shotConfig.shotAspect} >
      <Space direction="vertical">
        <Radio key='style1' value={'square'}>{t('menu.media.aspect.square', 'Square')}</Radio>
        <Radio key='style2' value={'landscape'}>{t('menu.media.aspect.landscape', 'Landscape')}</Radio>
        <Radio key='style3' value={'portrait'}>{t('menu.media.aspect.portrait', 'Portrait')}</Radio>
      </Space>
    </Radio.Group>
  </>);

  return (frameMedias);
}