/*
JetBrains Mono
* FiraCode
* Source Code Pro
* Inconsolata
* Iosevka
* Ubuntu Mono
* Github Monaspace
*/
const globalShotFontCodeData = [
  {
    key: 'jetbrains_mono',
    default: 'JetBrains Mono',
    fontFace: 'JetBrains Mono',
  },
  {
    key: 'fira_mono',
    default: 'Fira Mono',
    fontFace: 'Fira Mono',
  },
  {
    key: 'source_code_pro',
    default: 'Source Code Pro',
    fontFace: 'Source Code Pro',
  },
  {
    key: 'inconsolata',
    default: 'Inconsolata',
    fontFace: 'Inconsolata',
  },
  {
    key: 'iosevka',
    default: 'Iosevka',
    fontFace: 'Iosevka',
  },
  {
    key: 'ubuntu_mono',
    default: 'Ubuntu Mono',
    fontFace: 'Ubuntu Mono',
  },
  {
    key: 'github_monaspace',
    default: 'Github Monaspace',
    fontFace: 'Github Monaspace',
  },
  {
    key: 'cascadia_code',
    default: 'Cascadia Code',
    fontFace: 'CascadiaCode',
  },
  {
    key: 'sf_mono',
    default: 'SF Mono',
    fontFace: 'SF Mono',
  },
  {
    key: 'consolas',
    default: 'Consolas',
    fontFace: 'Consolas',
  },
]
export default globalShotFontCodeData;
