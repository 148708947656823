/**
 * @param url 图片路径
 * @param callback 结果回调
 */
export default function getUrlBase64Callback(url, callback) {
    let canvas = document.createElement("canvas"); //创建canvas DOM元素
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    //(url);
    //console.log('image loading');

    img.onload = function () {
        //console.log('image loaded');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL("image/jpeg", 1); //可选取多种模式
        callback.call(this, dataURL); //回掉函数获取Base64编码
        canvas = null;
    };
}
