const globalShotMediaData = [
  {
    key: 'devshots',
    default: 'DevShots',
    squareWidth: 1280,
    squareHeight: 1280,
    landscapeWidth: 1280,
    landscapeHeight: 960,
    portraitWidth: 960,
    portraitHeight: 1280,
  },
  {
    key: 'x',
    default: 'X',
    squareWidth: 1024,
    squareHeight: 1024,
    landscapeWidth: 1024,
    landscapeHeight: 536,
    portraitWidth: 766,
    portraitHeight: 1020,
  },
  {
    key: 'twitter',
    default: 'Twitter',
    squareWidth: 1024,
    squareHeight: 1024,
    landscapeWidth: 1024,
    landscapeHeight: 536,
    portraitWidth: 766,
    portraitHeight: 1020,
  },
  {
    key: 'threads',
    default: 'Threads',
    squareWidth: 1200,
    squareHeight: 1200,
    landscapeWidth: 1200,
    landscapeHeight: 672,
    portraitWidth: 900,
    portraitHeight: 1200,
  },
  {
    key: 'facebook',
    default: 'Facebook',
    squareWidth: 1200,
    squareHeight: 1200,
    landscapeWidth: 1200,
    landscapeHeight: 630,
    portraitWidth: 900,
    portraitHeight: 1200,
  },
  {
    key: 'instagram',
    default: 'Instagram',
    squareWidth: 1080,
    squareHeight: 1080,
    landscapeWidth: 1080,
    landscapeHeight: 566,
    portraitWidth: 1080,
    portraitHeight: 1350,
  },
  {
    key: 'pinterest',
    default: 'Pinterest',
    squareWidth: 1000,
    squareHeight: 1000,
    landscapeWidth: 1000,
    landscapeHeight: 750,
    portraitWidth: 1000,
    portraitHeight: 2000,
  },
  {
    key: 'reddit',
    default: 'Reddit',
    squareWidth: 1196,
    squareHeight: 1196,
    landscapeWidth: 1196,
    landscapeHeight: 624,
    portraitWidth: 624,
    portraitHeight: 1196,
  },
  {
    key: 'linkedin',
    default: 'LinkedIn',
    squareWidth: 1200,
    squareHeight: 1200,
    landscapeWidth: 1200,
    landscapeHeight: 627,
    portraitWidth: 1200,
    portraitHeight: 1500,
  },
  {
    key: 'tiktok',
    default: 'TikTok',
    squareWidth: 1080,
    squareHeight: 1080,
    landscapeWidth: 1920,
    landscapeHeight: 1080,
    portraitWidth: 1080,
    portraitHeight: 1920,
  },
  {
    key: 'xiaohongshu',
    default: 'XiaoHongShu',
    squareWidth: 1080,
    squareHeight: 1080,
    landscapeWidth: 1200,
    landscapeHeight: 900,
    portraitWidth: 900,
    portraitHeight: 1200,
  },
  {
    key: 'weibo',
    default: 'Weibo',
    squareWidth: 980,
    squareHeight: 980,
    landscapeWidth: 980,
    landscapeHeight: 900,
    portraitWidth: 800,
    portraitHeight: 1600,
  },
  {
    key: 'weixin',
    default: 'WeChat',
    squareWidth: 1200,
    squareHeight: 1200,
    landscapeWidth: 1200,
    landscapeHeight: 900,
    portraitWidth: 1200,
    portraitHeight: 1600,
  },
  {
    key: 'okjike',
    default: 'Jike',
    squareWidth: 1080,
    squareHeight: 1080,
    landscapeWidth: 1208,
    landscapeHeight: 640,
    portraitWidth: 640,
    portraitHeight: 1208,
  },
];

export default globalShotMediaData;
