import React from 'react';

import { Radio, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";

export default function ModelLayouts(shotConfig, handleShotConfig) {

  const { t } = useTranslation();
  const { Title } = Typography;


  const onChangeMode = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotMode = e.target.value;
    //setShotConfig(_shotConfig)
    handleShotConfig(_shotConfig)
  };
  const onChangePadding = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotPadding = e.target.value;
    // document.documentElement.style.setProperty('--shot-padding', _shotConfig.shotPadding + 'px');
    // document.documentElement.style.setProperty('--shot-device-frame-zoom', (1.0 - 0.1 * (_shotConfig.shotPadding - 30) / 30) + '');
    //setShotConfig(_shotConfig)
    handleShotConfig(_shotConfig)
  };
  const frameLayouts = (<>
    <Title level={4}>{t('menu.layout.mode.title', 'Content Mode')}</Title>
    <Radio.Group onChange={onChangeMode} value={shotConfig.shotMode} >
      <Space direction="vertical">
        <Radio key='mode1' value={'text'}>{t('menu.layout.mode.text', 'Text')}</Radio>
        <Radio key='mode2' value={'image'}>{t('menu.layout.mode.image', 'Image')}</Radio>
        <Radio key='mode3' value={'all1'}>{t('menu.layout.mode.all1', 'Text+Image')}</Radio>
        <Radio key='mode4' value={'all2'}>{t('menu.layout.mode.all2', 'Image+Text')}</Radio>
        <Radio key='mode5' value={'all3'}>{t('menu.layout.mode.all3', 'Text above Image')}</Radio>
        <Radio key='mode6' value={'all4'}>{t('menu.layout.mode.all4', 'Title+Device')}</Radio>
        <Radio key='mode7' value={'all5'}>{t('menu.layout.mode.all5', 'Device+Title')}</Radio>
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.layout.padding.title', 'Padding Size')}</Title>
    <Radio.Group onChange={onChangePadding} value={shotConfig.shotPadding} optionType="button" className='smallgrid'>
      <div>
        <Radio key='paddingsize1' value={'30'}>1</Radio>
        <Radio key='paddingsize2' value={'60'}>2</Radio>
        <Radio key='paddingsize3' value={'80'}>3</Radio>
        <Radio key='paddingsize4' value={'100'}>4</Radio>
        <Radio key='paddingsize5' value={'120'}>5</Radio>
      </div>
    </Radio.Group>
  </>);

  return (frameLayouts);
}