
import './BoxTopbar.scss';

import React from 'react';
import { Button, Space } from 'antd';
import { useTranslation } from "react-i18next";

import {
  ZoomInOutlined,
  ZoomOutOutlined,
  DragOutlined,
} from '@ant-design/icons';

export default function BoxTopbar({ handleTopbar, handleTopbarDownload }) {

  const { t } = useTranslation();

  //const { MenuProps } = Dropdown;
  const handleTopbarInput = (index) => {
    handleTopbar('showInput');
  }
  const handleTopbarConfig = (index) => {
    handleTopbar('showMenu');
  }
  const handleTopbarShuffle = (index) => {
     handleTopbar('shuffle');
  }
  const handleTopbarZoomIn = (index) => {
    handleTopbar('zoomin');
  }
  const handleTopbarZoomOut = (index) => {
    handleTopbar('zoomout');
  }
  const handleTopbarZoomFit = (index) => {
    handleTopbar('zoomfit');
  }
  
  return (<div id={"box-content-topbar"}>
    <Space>
      <Button.Group>
      <Button type="default" onClick={handleTopbarInput}>{t('topbar.input', 'Input')}</Button>
      <Button type="default" onClick={handleTopbarConfig}>{t('topbar.config', 'Config')}</Button>
      <Button type="default" onClick={handleTopbarShuffle}>{t('topbar.shuffle', 'Shuffle')}</Button>
      </Button.Group>
      <Button.Group>
      <Button type="default" onClick={handleTopbarZoomIn}  icon={<ZoomInOutlined />}/>
      <Button type="default" onClick={handleTopbarZoomFit}  icon={<DragOutlined />}/>
      <Button type="default" onClick={handleTopbarZoomOut}  icon={<ZoomOutOutlined />}/>
      </Button.Group>
      {/* 
      <Button type="primary" onClick={handleTopbarZoomIn}>{t('topbar.zoomin', 'Zoom In')}</Button>
      <Button type="primary" onClick={handleTopbarZoomOut}>{t('topbar.zoomin', 'Zoom Out')}</Button> */}
    </Space>
  </div>
  );
}
