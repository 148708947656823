

const utils = {}
utils.shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}
utils.guid = () => {
    return (Date.now().toString(36) + Math.random().toString(36).substring(2));
}
utils.deleteObjectFromArray = (arrayInput, keyValue) => {
    var array = arrayInput;
    for (var i = 0; i < arrayInput.length; i++) {
        var item = arrayInput[i];
        if (item.key === keyValue) {
            array.splice(i, 1);
        }
    }
    return array;
}
utils.getConfigFromArray = (arrayInput, keyValue) => {
    for (var i = 0; i < arrayInput.length; i++) {
        var item = arrayInput[i];
        if (item.key === keyValue) {
            return item.config;
        }
    }
    return {};
}
utils.getObjectFromArray = (arrayInput, keyValue) => {
    for (var i = 0; i < arrayInput.length; i++) {
        var item = arrayInput[i];
        if (item.key === keyValue) {
            return item;
        }
    }
    return {};
}
utils.getTitleFromContent = (content, defaultTitle='Content') => {
    const _content = content.split('\n');
    let _title = defaultTitle;
    if (_content.length > 0) {
        _title = _content[0];
        _title = _title.substring(0, 24);
    }
    return _title;
}

export default utils;