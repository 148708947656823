const globalShotScreenShotData = [
  {
    key: 'iphone_6_7',
    default: 'iPhone 6.7"',
    squareWidth: 1024/2,
    squareHeight: 1024/2,
    landscapeWidth: 2796/2,
    landscapeHeight: 1290/2,
    portraitWidth: 1290/2,
    portraitHeight: 2796/2,
  },
  {
    key: 'iphone_5_5',
    default: 'iPhone 5.5"',
    squareWidth: 1024/2,
    squareHeight: 1024/2,
    landscapeWidth: 2208/2,
    landscapeHeight: 1242/2,
    portraitWidth: 1242/2,
    portraitHeight: 2208/2,
  },
  {
    key: 'ipad_12_9',
    default: 'iPad 12.9"',
    squareWidth: 1024/2,
    squareHeight: 1024/2,
    landscapeWidth: 2732/2,
    landscapeHeight: 2048/2,
    portraitWidth: 2048/2,
    portraitHeight: 2732/2,
  },
];

export default globalShotScreenShotData;