//系统的
import React, { Suspense } from 'react';
import { useState, useEffect, useLayoutEffect } from 'react';


//别人的

import { ConfigProvider, theme, message, Layout } from 'antd';

import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import jaJP from 'antd/locale/ja_JP';
import koKR from 'antd/locale/ko_KR';



//import ReactJsonBeautify from 'react-json-beautify';
import 'react-json-beautify/react-json-beautify.css';

import HighlightCode from './lib/highlightCode';
//import 'highlight.js/styles/github-dark.css';
//import 'highlight.js/styles/tokyo-night-light.css';
//import 'highlight.js/styles/tokyo-night-dark.css';

//import PrettifyCode from './lib/prettifyCode.js';
//require('code-prettify/styles/desert.css') ;

//import htmlBeautify from 'html-beautify'
//import htmlBeautify from './lib/beautifyCode.min.js'
import JsBeautify from 'js-beautify';
import ReactMarkdown from 'react-markdown'

import { useTranslation } from "react-i18next";

// 我的

import global from './lib/global';

import Shot from './comp/Shot'
import BoxMenu from './comp/BoxMenu'
import BoxInput from './comp/BoxInput';
import BoxToolbar from './comp/BoxToolbar';
import BoxTopbar from './comp/BoxTopbar';
import BoxHeader from './comp/BoxHeader';
import Loading from "./comp/Loading";

import i18n from "./lib/i18n";
import shuffleConfig from './lib/shuffleConfig';
import getUrlBase64Callback from './lib/getUrlBase64';
import './scss/style.scss';

export default function App() {
  const ShotDeviceData = global.ShotDeviceData;

  const { t } = useTranslation();
  // 多语言支持
  const [lang, setLang] = useState('en');
  const [locale, setLocal] = useState(enUS);

  const AppHandleHeader = (e) => {
    setLang(e);
    if (e === 'jp') {
      setLocal({ jaJP });
      i18n.changeLanguage('jp');
    } else if (e === 'cn') {
      setLocal({ zhCN });
      i18n.changeLanguage('cn');
    } else if (e === 'kr') {
      setLocal({ koKR });
      i18n.changeLanguage('kr');
    } else {
      setLocal({ enUS });
      i18n.changeLanguage('en');
    }
  };
  // eslint-disable-next-line no-multi-str
  const [inputCode, setInputCode] = useState('');
  const [inputImage, setInputImage] = useState('');
  const [inputMarkdown, setInputMarkdown] = useState('');

  const [inputIsShow, setInputIsShow] = useState(0);
  const [menuIsShow, setMenuIsShow] = useState(0);
  const [outputCodeHTML, setOutputCodeHTML] = useState('');
  const [shotZoomMobile, setShotZoomMobile] = useState(0.30);
  const [appVersionUpdated, setAppVersionUpdated] = useState(false);
  const [shotConfig, setShotConfig] = useState({
    shotMedia: 'devshots',
    shotAspect: 'square',
    shotWidth: '1200',
    shotHeight: '1200',
    shotDevice: 'nodevice',
    shotMode: 'text',
    shotTextSize: 'normal',
    shotTextAlign: 'left',
    shotFontFace: 'font_default',
    shotPadding: '120',
    shotTheme: 'github-dark',
    shotHeader: '2',
    shotShadow: '0',
    shotDeviceGlass: '0',
    shotBackground: '0',
    shotTextColor: '0',
    shotTextBackground: '0',
    shotWatermarkStyle: '5',
    shotWatermarkUserName: '极客晒图',
    shotWatermarkUserID: 'DevShots',
    shotWatermarkLocation: '6',
  });



  useLayoutEffect(() => {
    const _inputCode = JSON.parse(localStorage.getItem('inputCode'));
    if (_inputCode) {
      setInputCode(_inputCode);
    } else {
      setInputCode(global.TestContent.contentMD);
    }
    const _inputImage = JSON.parse(localStorage.getItem('inputImage'));
    if (_inputImage) {
      setInputImage(_inputImage);
    } else {
      getUrlBase64Callback('apple-touch-icon.png', function (v) {
        setInputImage(v);
      })
    }
    const _shotConfig = JSON.parse(localStorage.getItem('shotConfig'));
    if (_shotConfig) {
      setShotConfig(_shotConfig);
      AppUpdateShotConfigCSS(_shotConfig)
    }
    const _lang = JSON.parse(localStorage.getItem('lang'));
    if (_lang) {
      AppHandleHeader(_lang);
    }
    const _appVersionUpdated = JSON.parse(localStorage.getItem('appVersionUpdated'));
    if (_appVersionUpdated) {
      setAppVersionUpdated(_appVersionUpdated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('appVersionUpdated', JSON.stringify(appVersionUpdated));
    localStorage.setItem('shotConfig', JSON.stringify(shotConfig));
    localStorage.setItem('inputCode', JSON.stringify(inputCode));
    localStorage.setItem('lang', JSON.stringify(lang));
    try {
      localStorage.setItem('inputImage', JSON.stringify(inputImage));
    } catch (error) {
      message.error(t('msg.error_size_oversize', 'Image\'s size is too big.'));
    }

  }, [shotConfig, inputCode, inputImage, lang, appVersionUpdated, t]);

  useEffect(() => {
    function appCheckUpdated() {
      const _appVersionUpdated = JSON.parse(localStorage.getItem('appVersionUpdated'));
      const _inputCode = JSON.parse(localStorage.getItem('inputCode'));
      //console.log(!(_appVersionUpdated === global.App.version));
      if (!(_appVersionUpdated === global.App.version)) {
        setAppVersionUpdated(global.App.version);
        //console.log('AppVersionUpdateCheck run update ....')
        updateAuto(global.TestContent.contentMD);
      } else {
        //console.log('AppVersionUpdateCheck run last ....')
        updateAuto(_inputCode);
      }

    }
    setTimeout(function () { appCheckUpdated() }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const highlightCode = HighlightCode;
  const jsBeautifyHtml = JsBeautify.html;
  const jsBeautifyCss = JsBeautify.css;
  const jsBeautifyJs = JsBeautify.js;

  function AppUpdateShotConfigCSS(_shotConfig) {
    document.documentElement.style.setProperty('--shot-aspect', _shotConfig.shotAspect);
    document.documentElement.style.setProperty('--shot-width', _shotConfig.shotWidth + 'px');
    document.documentElement.style.setProperty('--shot-height', _shotConfig.shotHeight + 'px');
    document.documentElement.style.setProperty('--shot-padding', _shotConfig.shotPadding + 'px');
    document.documentElement.style.setProperty('--shot-device-frame-zoom', (1.0 - 0.1 * (_shotConfig.shotPadding - 30) / 30) + '');

    ShotDeviceData.forEach(element => {
      if (element.key === _shotConfig.shotDevice) {
        document.documentElement.style.setProperty('--shot-device-frame-width', element.frameWidth + 'px');
        document.documentElement.style.setProperty('--shot-device-frame-height', element.frameHeight + 'px');
        document.documentElement.style.setProperty('--shot-device-content-width', element.contentWidth + 'px');
        document.documentElement.style.setProperty('--shot-device-content-height', element.contentHeight + 'px');
        document.documentElement.style.setProperty('--shot-device-content-top', element.contentTop + 'px');
        document.documentElement.style.setProperty('--shot-device-content-left', element.contentLeft + 'px');
        document.documentElement.style.setProperty('--shot-device-content-radius', element.contentRadius + 'px');
        document.documentElement.style.setProperty('--shot-device-image', 'url("' + element.image + '")');
        document.documentElement.style.setProperty('--shot-device-image-mockup', 'url("' + element.imageMockup + '")');
      }
    });

  }
  //const prettifyCode = PrettifyCode;
  function updateAuto(inputContent) {
    let html1 = inputCode;
    if (!!inputContent) {
      html1 = inputContent;
      //setInputCode(inputContent);
    }
    //console.log(inputContent)
    if (html1 === '') return;

    if (html1.includes('</')) {
      updateCode('html', inputContent);
    } else if (html1.includes('display:') || html1.includes('border') || html1.includes('position') || html1.includes('padding') || html1.includes('margin')) {
      updateCode('css', inputContent);
    } else if (html1.includes('if ') || html1.includes('var ') || html1.includes('function ') || html1.includes('return ')) {
      updateCode('js', inputContent);
    } else if (html1.startsWith('{')) {
      updateCode('json', inputContent);
    } else if (html1.includes('select') || html1.includes('insert') || html1.includes('update')) {
      updateCode('sql', inputContent);
    } else if (html1.includes('#')) {
      updateCode('markdown', inputContent);
    } else {
      updateCode('auto', inputContent);
    }
    return;
  }

  function updateCode(language = '', inputContent) {

    let html1 = inputCode;
    if (!!inputContent) {
      html1 = inputContent;
      //setInputCode(inputContent);
    }

    let html2 = '';
    if (language === 'html') {
      message.success('HTML ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyHtml(html1)
      html2 = highlightCode(html1, { 'language': 'HTML' });
    }
    if (language === 'css') {
      message.success('CSS ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyCss(html1)
      html2 = highlightCode(html1, { 'language': 'css' });
    }
    if (language === 'js') {
      message.success('JavaScript ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyJs(html1)
      html2 = highlightCode(html1, { 'language': 'js' });
    }
    if (language === 'json') {
      message.success('JSON ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyJs(html1)
      html2 = highlightCode(html1, { 'language': 'json' });
    }
    if (language === 'java') {
      message.success('Java ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyJs(html1)
      html2 = highlightCode(html1, { 'language': 'java' });
    }
    if (language === 'sql') {
      message.success('SQL ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyJs(html1)
      html2 = highlightCode(html1, { 'language': 'sql' });
    }
    if (language === 'markdown') {
      message.success('Markdown ' + t('msg.processing', 'content processing...'));
      setInputMarkdown(html1);
      //html2 = document.getElementById('box-markdown').innerHTML;
      setTimeout(function () { updateCode('markdown_callback'); }, 100);
    }

    if (language === 'markdown_callback') {
      html2 = document.getElementById('box-markdown').innerHTML;
    }

    if (language === 'plain') {
      message.success('Plain ' + t('msg.processing', 'content processing...'));
      html2 = html1;
    }
    if (language === 'auto') {
      message.success('Auto ' + t('msg.processing', 'content processing...'));
      html1 = jsBeautifyJs(html1)
      html2 = highlightCode(html1);
    }
    if (!(language === 'markdown') && html2 === '') {
      if (!!language) {
        message.success('Code ' + t('msg.processing', 'content processing...'));
        html1 = jsBeautifyJs(html1)
        html2 = highlightCode(html1, { 'language': language });
      }
    }
    setOutputCodeHTML(html2);
  }


  function AppHandleTopbar(e) {
    if (e === 'zoomfit') {
      document.documentElement.style.setProperty('--shot-zoom', 0.30);
      setShotZoomMobile(0.30);
      return;
    }
    if (e === 'zoomin') {
      if (shotZoomMobile <= 0.40) {
        document.documentElement.style.setProperty('--shot-zoom', shotZoomMobile + 0.02);
        setShotZoomMobile(shotZoomMobile + 0.02);
      }
      return;
    }
    if (e === 'zoomout') {
      if (shotZoomMobile >= 0.20) {
        document.documentElement.style.setProperty('--shot-zoom', shotZoomMobile - 0.02);
        setShotZoomMobile(shotZoomMobile - 0.02);
      }
      return;
    }
    if (e === 'showInput') {
      setInputIsShow(1 - inputIsShow);
      return;
    }
    if (e === 'showMenu') {
      setMenuIsShow(1 - menuIsShow);
      return;
    }
    if (e === 'shuffle') {
      let newConfig = shuffleConfig(shotConfig);
      AppHandleShotConfig(newConfig)
      //setShotConfig(newConfig);
    }
  }

  function blobToDataURI(blob, callback) {
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(e.target?.result);
    };
    reader.readAsDataURL(blob);
  }


  function AppHandleToolbar(e) {
    if (e === 'paste') {
      let text = '';
      async function getClipboardContents() {
        try {
          const clipboardItems = await navigator.clipboard.read();
          for (const clipboardItem of clipboardItems) {
            //console.log(clipboardItem.types);
            if (clipboardItem.types.includes("image/png")) {
              const blob = await clipboardItem.getType("image/png");
              //setInputImage(URL.createObjectURL(blob));
              blobToDataURI(blob, function (e) {
                setInputImage(e);
              });
            } else {
              if (clipboardItem.types.includes("text/plain")) {
                //console.log("text/plain");
                const blob = await clipboardItem.getType("text/plain");
                text = await blob.text();
                updateAuto(text);
              }
            }
            //console.log(URL.createObjectURL(blob));
          }
        } catch (err) {
          console.error(err.name, err.message);
        }
      }
      getClipboardContents();
      return;
    }
    if (e === 'shuffle') {
      let newConfig = shuffleConfig(shotConfig);
      AppHandleShotConfig(newConfig)
      return;
    }
    if (e === 'jpg' || e === 'png') {
      AppDownload(e)
      return;
    }
    if (e === 'auto') {
      updateAuto();
    } else {
      updateCode(e);
    }
  }
  function AppHandleToolbarDownload(e) {
    AppDownload('jpg')
  }
  function AppDownload(e) {
    try {

      const c = document.querySelector('#canvas_save');
      const cx = c.getContext('2d');

      let canvas = document.getElementById("shot_canvas");
      let canvas_image = canvas.toDataURL("image/png");

      var img = new Image();
      img.src = canvas_image;
      img.onload = function () {
        // add the image to the container
        //imagecontainer.appendChild(img);
        // get the size of the image
        let w = parseInt(img.naturalWidth);
        let h = parseInt(img.naturalHeight);
        // define a zoom factor
        let zoomfactor = 0.5;
        // resize the canvas to that size
        c.width = parseInt(w * zoomfactor);
        c.height = parseInt(h * zoomfactor);
        // important! this makes sure canvas doesn't try to smooth 
        // the pixels
        cx.imageSmoothingEnabled = true;
        cx.imageSmoothingQuality = "high";
        // scale the context's coordinate system 
        cx.scale(zoomfactor, zoomfactor);
        // paint the image
        cx.drawImage(img, 0, 0);
        // set the href of the link to the BASE64 version of the image
        let start = Date.now();
        let link = document.createElement("a");
        if (e === 'png') {
          link.href = c.toDataURL("image/png");
          link.download = "devshots-" + start + ".png";
        } else {
          link.href = c.toDataURL("image/jpeg");
          link.download = "devshots-" + start + ".jpg";
        }
        link.click();
        // tell the user the functionality is ready
        // document.querySelector('#savemessage').innerText = `
        //   Click image to save as "zoomed.png"
        // `;
      };
    } catch (error) {
      setTimeout(function(){
        AppDownload(e);
      },2000)
      message.info(t('msg.waiting', 'Wait a moment.'));
    }
  }
  // function AppHandleToolbarDownload2(e) {
  //   try {
  //     let canvas = document.getElementById("shot_canvas");
  //     let start = Date.now();
  //     let link = document.createElement("a");
  //     link.href = canvas.toDataURL("image/png");
  //     link.download = "x-shots-" + start + ".png";
  //     link.click();
  //   } catch (error) {
  //     message.error('waiting a minute...');
  //   }
  // }

  function AppHandleInputCode(s) {
    setInputCode(s);
  }

  function AppHandleInputIsShow(s) {
    setInputIsShow(s);
  }
  function AppHandleMenuIsShow(s) {
    setMenuIsShow(s);
  }

  function AppHandleShotConfig(_shotConfig) {
    setShotConfig({
      shotMedia: _shotConfig.shotMedia,
      shotAspect: _shotConfig.shotAspect,
      shotWidth: _shotConfig.shotWidth,
      shotHeight: _shotConfig.shotHeight,
      shotDevice: _shotConfig.shotDevice,
      shotMode: _shotConfig.shotMode,
      shotTextSize: _shotConfig.shotTextSize,
      shotTextAlign: _shotConfig.shotTextAlign,
      shotFontFace: _shotConfig.shotFontFace,
      shotPadding: _shotConfig.shotPadding,
      shotTheme: _shotConfig.shotTheme,
      shotHeader: _shotConfig.shotHeader,
      shotShadow: _shotConfig.shotShadow,
      shotDeviceGlass: _shotConfig.shotDeviceGlass,
      shotBackground: _shotConfig.shotBackground,
      shotTextColor: _shotConfig.shotTextColor,
      shotTextBackground: _shotConfig.shotTextBackground,
      shotWatermarkStyle: _shotConfig.shotWatermarkStyle,
      shotWatermarkUserName: _shotConfig.shotWatermarkUserName,
      shotWatermarkUserID: _shotConfig.shotWatermarkUserID,
      shotWatermarkLocation: _shotConfig.shotWatermarkLocation,
    });

    AppUpdateShotConfigCSS(_shotConfig);
    // ShotDeviceData.forEach(element => {
    //   if(element.key===_shotConfig.shotDevice){
    //     document.documentElement.style.setProperty('--shot-device-frame-width', element.frameWidth + 'px');
    //     document.documentElement.style.setProperty('--shot-device-frame-height', element.frameHeight + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-width', element.contentWidth + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-height', element.contentHeight + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-top', element.contentTop + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-left', element.contentLeft + 'px');
    //     document.documentElement.style.setProperty('--shot-device-content-radius', element.contentRadius + 'px');
    //     document.documentElement.style.setProperty('--shot-device-image', 'url("' + element.image + '")' );
    //     document.documentElement.style.setProperty('--shot-device-image-mockup', 'url("' + element.imageMockup + '")' );
    //   }
    // });

  }


  return (
    <Suspense fallback={<Loading />}>
      <ConfigProvider
        locale={locale}
        theme={{
          // 1. Use dark algorithm
          algorithm: theme.darkAlgorithm,

          // 2. Combine dark algorithm and compact algorithm
          //algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        }}
      >
        <Layout>
          <BoxHeader handleHeader={AppHandleHeader} />
          <Layout>
            <BoxInput inputCode={inputCode} handleInputCode={AppHandleInputCode} inputImage={inputImage} handleButtonClick={AppHandleToolbar} inputIsShow={inputIsShow} handleInputIsShow={AppHandleInputIsShow} />
            <Layout id={"box-content"}>
              <BoxTopbar handleTopbar={AppHandleTopbar} />
              <Shot shotConfig={shotConfig} outputCodeHTML={outputCodeHTML} outputImage={inputImage} />
            </Layout>
            <BoxToolbar handleToolbar={AppHandleToolbar} handleToolbarDownload={AppHandleToolbarDownload} />
            <BoxMenu shotConfig={shotConfig} handleShotConfig={AppHandleShotConfig} menuIsShow={menuIsShow} handleMenuIsShow={AppHandleMenuIsShow} />
            <div id='box-markdown'>
              <ReactMarkdown children={inputMarkdown} />
            </div>
            <div id='box-canvas-save'>
              <canvas id='canvas_save' />
            </div>
          </Layout>
        </Layout>
      </ConfigProvider>
    </Suspense>
  );
}

//export default App;
