import React from 'react';

import { Radio, Typography } from 'antd';
import { useTranslation } from "react-i18next";

export default function ModelColors(shotConfig, handleShotConfig) {

  const { t } = useTranslation();
  const { Title } = Typography;


  const onChangeBackground = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotBackground = e.target.value;
    handleShotConfig(_shotConfig)
  };
  const onChangeTextColor = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotTextColor = e.target.value;
    handleShotConfig(_shotConfig)
  };
  const onChangeTextBackground = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotTextBackground = e.target.value;
    handleShotConfig(_shotConfig)
  };

  const frameColors = (<>
    <Title level={4}>{t('menu.color.background.title', 'Background')}</Title>
    <Radio.Group onChange={onChangeBackground} value={shotConfig.shotBackground} optionType="button" id="RadioBackground">
      <div>
        {new Array(90).fill(null).map((_, i) => {
          return <Radio key={i} value={'' + i}><div className={"menu-background menu-background-" + i}></div></Radio>;
        })}
      </div>
    </Radio.Group>
    <Title level={4}>{t('menu.color.text_color.title', 'Text Color')}</Title>
    <Radio.Group onChange={onChangeTextColor} value={shotConfig.shotTextColor} optionType="button" id="RadioTextColor">
      <div>
        {new Array(80).fill(null).map((_, i) => {
          return <Radio key={'TextColor' + i} value={'' + i}><div className={"menu-text-color menu-text-color-" + i}></div></Radio>;
        })}
      </div>
    </Radio.Group>
    <Title level={4}>{t('menu.color.text_background.title', 'Text Background')}</Title>
    <Radio.Group onChange={onChangeTextBackground} value={shotConfig.shotTextBackground} optionType="button" id="RadioTextBackground">
      <div>
        {new Array(80).fill(null).map((_, i) => {
          return <Radio key={'TextBackground' + i} value={'' + i}><div className={"menu-text-background menu-text-background-" + i}></div></Radio>;
        })}
      </div>
    </Radio.Group>
  </>);

  return (frameColors);
}