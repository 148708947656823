import hljs from 'highlight.js';

export default function HighlightCode(dataInputCode, option) {
  if (!!option) {
    //console.log(option);
    return hljs.highlight(dataInputCode,option).value;
  }
  else {
    return hljs.highlightAuto(dataInputCode).value;
  }
}
