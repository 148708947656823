//系统的
import React from 'react';


export default function Loading() {

  return (
    <div style={{ padding: '0 24px' }}>
      ...
    </div>
  );
}
