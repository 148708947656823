import React from 'react';

import { Radio, Typography } from 'antd';
import { useTranslation } from "react-i18next";

export default function ModelEffects(shotConfig, handleShotConfig) {

  const { t } = useTranslation();
  const { Title } = Typography;


  const onChangeHeader = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotHeader = e.target.value;
    handleShotConfig(_shotConfig)
  };


  const onChangeShadow = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotShadow = e.target.value;
    handleShotConfig(_shotConfig)
  };
  const frameEffects = (<>
    <Title level={4}>{t('menu.effect.mockup.title', 'Mockup')}</Title>
    <Radio.Group onChange={onChangeHeader} value={shotConfig.shotHeader}>
      <div>
        <Radio key='Header0' value={'0'}>{t('menu.effect.mockup.hide', 'Hide')}</Radio>
        <Radio key='Header1' value={'1'}>{t('menu.effect.mockup.mac_icon', 'Mac Icon')}</Radio>
        <Radio key='Header2' value={'2'}>{t('menu.effect.mockup.mac_bar', 'Mac Bar')}</Radio>
        <Radio key='Header3' value={'3'}>{t('menu.effect.mockup.windows_bar', 'Windows Bar')}</Radio>
        <Radio key='Header4' value={'4'}>{t('menu.effect.mockup.iphone_info_bar', 'iPhone Info Bar')}</Radio>
      </div>
    </Radio.Group>
    <Title level={4}>{t('menu.effect.shadow.title', 'Shadow')}</Title>
    <Radio.Group onChange={onChangeShadow} value={shotConfig.shotShadow}>
      <div>
        <Radio key='shadowAuto' value={'0'}>{t('menu.effect.shadow.default', 'Default')}</Radio>
        <Radio key='shadowNone' value={'1'}>{t('menu.effect.shadow.hide', 'Hide')}</Radio>
        <Radio key='shadowNormal' value={'2'}>{t('menu.effect.shadow.normal', 'Normal')}</Radio>
        <Radio key='shadowPaper' value={'3'}>{t('menu.effect.shadow.paper', 'Paper')}</Radio>
        <Radio key='shadowLight' value={'4'}>{t('menu.effect.shadow.light', 'Light')}</Radio>
        <Radio key='shadowRound' value={'5'}>{t('menu.effect.shadow.round', 'Round')}</Radio>
      </div>
    </Radio.Group>
  </>);
  return (frameEffects);
}