import React from 'react';
import { useState, useEffect } from 'react';
import html2canvas from 'html2canvas-pro';
import './Shot.scss'
import global from '../lib/global';

export default function Shot({ shotConfig, outputCodeHTML, outputImage }) {

  window.dataLayer = window.dataLayer || [];

  const [prevOutputCodeHTML, setPrevOutputCodeHTML] = useState(outputCodeHTML);
  const [prevOutputImage, setPrevOutputImage] = useState(outputImage);
  const [prevShotConfig, setPrevShotConfig] = useState(shotConfig);
  const [cssShotDevice, setCssShotDevice] = useState('');

  useEffect(() => {
    if (shotConfig.shotDevice === 'nodevice') {
      setCssShotDevice("shot-device-nodevice"); //DevShot Media responsive CssName
    } else {
      setCssShotDevice("shot-device shot-device-glass" + shotConfig.shotDeviceGlass + " shot-device-" + shotConfig.shotDevice + " shot-device-" + shotConfig.shotDevice + "-" + shotConfig.shotAspect);
    }

  }, [shotConfig]);

  function isSomeObject(object1, object2) {
    return (object1.shotMedia === object2.shotMedia
      && object1.shotAspect === object2.shotAspect
      && object1.shotDevice === object2.shotDevice
      && object1.shotMode === object2.shotMode
      && object1.shotTextSize === object2.shotTextSize
      && object1.shotTextAlign === object2.shotTextAlign
      && object1.shotFontFace === object2.shotFontFace
      && object1.shotPadding === object2.shotPadding
      && object1.shotTheme === object2.shotTheme
      && object1.shotBackground === object2.shotBackground
      && object1.shotTextColor === object2.shotTextColor
      && object1.shotTextBackground === object2.shotTextBackground
      && object1.shotHeader === object2.shotHeader
      && object1.shotShadow === object2.shotShadow
      && object1.shotDeviceGlass === object2.shotDeviceGlass
      && object1.shotWatermarkStyle === object2.shotWatermarkStyle
      && object1.shotWatermarkUserName === object2.shotWatermarkUserName
      && object1.shotWatermarkUserID === object2.shotWatermarkUserID
      && object1.shotWatermarkLocation === object2.shotWatermarkLocation);
  }

  function updateConfigProcess() {
    if (document.getElementById('shot_image')) document.getElementById('shot_image').innerHTML = '';
    if (!window.isMakeingShot) {
      setTimeout(makeShot, 1500);
      window.isMakeingShot = true;
    }
  }

  function makeShot() {
    let shotHtml = document.getElementById('shot_html');
    document.getElementById('shot_to_make').innerHTML = shotHtml.innerHTML;
    
    let shotToMake = document.getElementById('shot_to_make');
    let download_scale = 2;
    if (global.isScreenShot(shotConfig.shotMedia)) {
      download_scale = 4;
    }
    
    let setup = {
      logging: true,
      scale: download_scale, // screen shot x4
      width: shotToMake.clientWidth,
      height: shotToMake.clientHeight,
    };
    html2canvas(shotToMake, setup).then(function (canvas) {
      canvas.id = 'shot_canvas';
      document.getElementById('shot_image').innerHTML = '';
      document.getElementById('shot_image').appendChild(canvas);
    });

    window.isMakeingShot = false;

  }
  if (!isSomeObject(prevShotConfig, shotConfig)) {

    // console.log("shotConfig<>prevShotConfig");
    //console.log(shotConfig);
    // console.log(prevShotConfig);
    setPrevShotConfig({
      shotMedia: shotConfig.shotMedia,
      shotAspect: shotConfig.shotAspect,
      shotWidth: shotConfig.shotWidth,
      shotHeight: shotConfig.shotHeight,
      shotDevice: shotConfig.shotDevice,
      shotMode: shotConfig.shotMode,
      shotTextSize: shotConfig.shotTextSize,
      shotTextAlign: shotConfig.shotTextAlign,
      shotFontFace: shotConfig.shotFontFace,
      shotPadding: shotConfig.shotPadding,
      shotTheme: shotConfig.shotTheme,
      shotHeader: shotConfig.shotHeader,
      shotShadow: shotConfig.shotShadow,
      shotDeviceGlass: shotConfig.shotDeviceGlass,
      shotTextColor: shotConfig.shotTextColor,
      shotBackground: shotConfig.shotBackground,
      shotTextBackground: shotConfig.shotTextBackground,
      shotWatermarkStyle: shotConfig.shotWatermarkStyle,
      shotWatermarkUserName: shotConfig.shotWatermarkUserName,
      shotWatermarkUserID: shotConfig.shotWatermarkUserID,
      shotWatermarkLocation: shotConfig.shotWatermarkLocation,
    });
    updateConfigProcess();
  }

  if (!(prevOutputCodeHTML === outputCodeHTML)) {
    //console.log("prevOutputCodeHTML<>outputCodeHTML");
    setPrevOutputCodeHTML(outputCodeHTML);
    updateConfigProcess();
  }


  if (!(prevOutputImage === outputImage)) {
    //console.log("prevOutputImage<>outputImage");
    setPrevOutputImage(outputImage);
    updateConfigProcess();
  }

  return (<>
    <div className={"show-container"}>
      <div className={"shot shot-media-" + shotConfig.shotMedia + " shot-aspect-" + shotConfig.shotAspect + " " + cssShotDevice + " shot-shadow-" + shotConfig.shotShadow + " shot-content-mode-" + shotConfig.shotMode}>
        <div id="shot_html" className="shot-html">
          <div className="shot-backgroundimage" style={{ backgroundImage: `url(` + outputImage + `)` }}>
          </div>
          <div className={"shot-layout theme-" + shotConfig.shotTheme + " shot-padding-" + shotConfig.shotPadding + " shot-padding-watermark-" + shotConfig.shotWatermarkStyle + " header-" + shotConfig.shotHeader + " font-" + shotConfig.shotTextSize + " font-" + shotConfig.shotTextAlign + " font-" + shotConfig.shotFontFace + " background background-" + shotConfig.shotBackground + "  shot-text-color-" + shotConfig.shotTextColor + " shot-text-background-" + shotConfig.shotTextBackground}>
            <div className='content-container'>
              <div className='content-container-shadow'>
                <div className={'content content-p content-mode-' + shotConfig.shotMode}>
                  <div className="header-bar">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="content-text">
                    <pre dangerouslySetInnerHTML={{ __html: outputCodeHTML }} />
                  </div>
                  <div className="content-image">
                    <img alt="shot-content-img" src={outputImage} />
                  </div>
                  <div className="device-text-box">
                    <div className="device-text">
                      <pre dangerouslySetInnerHTML={{ __html: outputCodeHTML }} />
                    </div>
                  </div>
                  <div className="device-image-box">
                    <div className="device-image">
                      <img alt="shot-device-img" src={outputImage} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"watermark watermark-location-" + shotConfig.shotWatermarkLocation + " watermark-style-" + shotConfig.shotWatermarkStyle}>
                <div className='watermark-content-logo' />
                <div className='watermark-content-user'>
                  <div className='watermark-content-user-name'>{shotConfig.shotWatermarkUserName}</div>
                  <div className='watermark-content-user-id'>{shotConfig.shotWatermarkUserID}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="shot_to_make" className="shot-html" />
        <div id="shot_image" className="shot-image" />
      </div>
    </div>
  </>
  );
}
