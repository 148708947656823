import './BoxInput.scss';

import React from 'react';
import { Input, Typography, Space, Button, Image } from 'antd';
import { useTranslation } from "react-i18next";
import global from '../lib/global';

export default function BoxInput({ inputIsShow, handleInputIsShow, inputCode, handleInputCode, inputImage, handleButtonClick }) {

    function gtag() { window.dataLayer.push(arguments); }

    const testContent = global.TestContent;

    const { Title } = Typography;
    const { t } = useTranslation();

    function handleClickAddHTML() {
        handleInputCode(testContent.contentHTML);
    }

    function handleClickAddJS() {
        handleInputCode(testContent.contentJS);
    }

    function handleClickAddCSS() {
        handleInputCode(testContent.contentCSS);
    }

    function handleClickAddJSON() {
        handleInputCode(testContent.contentJSON);
    }

    // function handleClickAddSQL() {
    //     handleInputCode(testContent.contentSQL);
    // }
    function handleClickAddCN() {
        handleInputCode(testContent.contentCN);
    }
    function handleClickAddJP() {
        handleInputCode(testContent.contentJP);
    }
    function handleClickAddMD() {
        handleInputCode(testContent.contentMD);
    }

    function handleClickClose(event) {
        handleInputIsShow(0);
        event.preventDefault();
    }
    function handleClickPaste(event) {
        gtag('event', 'click', {
            'element_id': 'paste'
        });
        handleInputIsShow(0);
        handleButtonClick('paste');
    }
    function handleClickAuto(event) {
        gtag('event', 'click', {
            'element_id': 'auto'
        });
        handleInputIsShow(0);
        handleButtonClick('auto');
    }
    return (<div className={'box-input box-input-' + inputIsShow}>
        <Space direction="vertical">
            <Title level={4}>{t('input.code', 'Input Your Code')}</Title>
            <Input.TextArea rows={16} value={inputCode} onChange={e => {
                handleInputCode(e.target.value);
            }}
                style={{ width: '100%' }} />
            <Space
                style={{ width: '100%', textAlign: 'center' }} >
                <Image id='boxInputImage' src={inputImage} />
            </Space>
            <Space
                style={{ width: '100%', textAlign: 'center' }} >
                <Button type="primary" block onClick={handleClickPaste}>{t('input.paste', 'Paste')}</Button>
                <Button type="primary" block onClick={handleClickAuto}>{t('input.auto', 'Auto')}</Button>
            </Space>
            {/* <Text type="warning"><BulbOutlined /> {t('input.prompt', 'Click Auto Button or Select Language.')}</Text> */}
            {/* <Title level={5}>Link</Title>
            <Search
                placeholder="input link URL"
                allowClear
                enterButton="Search"
                onChange={e => {
                    handleInputLink(e.target.value);
                }}
                onSearch={handleInputLinkProcess}
            /> */}
            <Title level={4}>{t('input.test', 'Try Youself')}</Title>
            <Space>
                <Button.Group>
                    <Button type="default" onClick={handleClickAddHTML} size="small">HTML</Button>
                    <Button type="default" onClick={handleClickAddJS} size="small">JS</Button>
                    <Button type="default" onClick={handleClickAddCSS} size="small">CSS</Button>
                    <Button type="default" onClick={handleClickAddJSON} size="small">JSON</Button>
                    <Button type="default" onClick={handleClickAddMD} size="small">MD</Button>
                    {/* <Button type="default" onClick={handleClickAddSQL} size="small">SQL</Button> */}
                    <Button type="default" onClick={handleClickAddCN} size="small">CN</Button>
                    <Button type="default" onClick={handleClickAddJP} size="small">JP</Button>
                </Button.Group>
            </Space>
        </Space>
        <div className='box-input-close' onClick={handleClickClose}></div>
    </div>
    );
}
