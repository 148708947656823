import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//ximport Backend from 'i18next-http-backend';
import Backend from 'i18next-xhr-backend';
// import * as config from "../locales/en/translation.json";
// console.log(config);
// console.log(config.head_logo_text);
i18n  // learn more: https://github.com/i18next/i18next-xhr-backend  
    .use(Backend)  // connect with React  
    .use(initReactI18next)  // for all options read: https://www.i18next.com/overview/configuration-options  
    .init({
        debug: false,
        lng: 'en',
        fallbackLng: 'en',
        whitelist: ['en', 'cn'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default    
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            stringify: JSON.stringify
        },
        react: {
            useSuspense: false
        },
    });
export default i18n;