import React from 'react';
import { Tabs } from 'antd';
import './BoxMenu.scss';


import { useTranslation } from "react-i18next";
//import global from '../lib/global';
import ModelMedias from '../model/ModelMedias.js';
import ModelDevices from '../model/ModelDevices.js';
import ModelThemes from '../model/ModelThemes.js';
import ModelLayouts from '../model/ModelLayouts.js';
import ModelFavorites from '../model/ModelFavorites.js'
import ModelFonts from '../model/ModelFonts.js';
import ModelColors from '../model/ModelColors.js';
import ModelEffects from '../model/ModelEffects.js';
import ModelOptions from '../model/ModelOptions.js';

export default function BoxMenu({ menuIsShow, handleMenuIsShow, shotConfig, handleShotConfig }) {


  const { t } = useTranslation();

  function handleClickClose(event) {
    handleMenuIsShow(0);
    event.preventDefault();
  }

  const modelFavorites = ModelFavorites(handleShotConfig);
  const modelDevices = ModelDevices(shotConfig, handleShotConfig);
  const modelMedias = ModelMedias(shotConfig, handleShotConfig);
  const modelThemes = ModelThemes(shotConfig, handleShotConfig);
  const modelLayouts = ModelLayouts(shotConfig, handleShotConfig);
  const modelFonts = ModelFonts(shotConfig, handleShotConfig);
  const modelColors = ModelColors(shotConfig, handleShotConfig);
  const modelEffects = ModelEffects(shotConfig, handleShotConfig);
  const modelOptions = ModelOptions(shotConfig, handleShotConfig);

  const TabItems = [modelMedias, modelDevices, modelThemes, modelLayouts, modelFonts, modelColors, modelEffects, modelOptions, modelFavorites];

  const TabNames = [t('menu.media.tab_name', 'Media'), t('menu.device.tab_name', 'Device'), t('menu.theme.tab_name', 'Theme'), t('menu.layout.tab_name', 'Layout'), t('menu.font.tab_name', 'Font'), t('menu.color.tab_name', 'Color'), t('menu.effect.tab_name', 'Effect'), t('menu.option.tab_name', 'Option'), t('menu.favorite.tab_name', 'Favorites')];

  return (
    <div className={'box-menu box-menu-' + menuIsShow}><Tabs
      defaultActiveKey="0"
      tabPosition="left"
      type="card"
      style={{ height: '100%' }}
      items={new Array(TabItems.length).fill(null).map((_, i) => {
        const id = String(i);
        return {
          key: 'tab_' + id,
          label: TabNames[i],
          //disabled: i === 28,
          children: TabItems[i],
        };
      })}
    />
      <div className='box-menu-close' onClick={handleClickClose}></div>
    </div>
  );
}
