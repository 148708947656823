import React from 'react';

import { useState, useEffect, useLayoutEffect } from 'react';
import { Typography, Space, Button } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { useTranslation } from "react-i18next";

import utils from '../lib/utils';
import './ModelFavorites.scss';


import global from '../lib/global';

export default function ModelFavorites(onApplyFavorite) {

    const { t } = useTranslation();
    const { Title } = Typography;

    const [favList, setFavList] = useState([]);
    const [favCount, setFavCount] = useState(0);

    const updateShotSize = global.updateShotSize;

    useLayoutEffect(() => {
        const _favCount = JSON.parse(localStorage.getItem('fav_count'));
        if (_favCount) {
            setFavCount(_favCount);
        } else {
            setFavCount(0);
        }
        const _favList = JSON.parse(localStorage.getItem('fav_list'));
        if (_favList) {
            setFavList(_favList);
        } else {
            setFavList([]);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('fav_list', JSON.stringify(favList));
        localStorage.setItem('fav_count', JSON.stringify(favCount));
    }, [favList, favCount]);

    const onFavoritesAdd = (e) => {
        const _shotConfig = JSON.parse(localStorage.getItem('shotConfig'));
        const _guid = utils.guid();
        const _content = utils.getTitleFromContent(JSON.parse(localStorage.getItem('inputCode')));
        const _contents = _content.split("\n");
        const _title = _contents[0].replace(/#/g,'');
        const _favitem = { "key": _guid, "title": _title,"content": _content, "config": _shotConfig };

        let _favList = favList;

        _favList.push(_favitem);
        setFavList(_favList);
        setFavCount(_favList.length);
    }
    const onFavoritesDelete = (key) => {
        setFavList(utils.deleteObjectFromArray(favList, key));
        setFavCount(favList.length);
    }

    const onFavoritesApply = (key) => {
        var item = utils.getObjectFromArray(favList, key);
        console.log(item);
        console.log(item.content);
        const config = updateShotSize(item.config);
        console.log(config);
        onApplyFavorite(config);

    }
    const thumbnailMouseOverHandler = (e) => {
        document.getElementsByClassName('fav-item-'+e)[0].classList.add('on');
    }
    const thumbnailMouseOutHandler = (e) => {
        document.getElementsByClassName('fav-item-'+e)[0].classList.remove('on');
    }
    return (<>
        <Title level={5}>{t('menu.favorite.title', 'Favorites List')}</Title>
        <div className={"fav-list"}>
            <Space style={{ width: '90%' }}>
                <Button type="primary" block onClick={onFavoritesAdd}>{t('menu.favorite.add', 'Add To My Favorites')}</Button>
            </Space>
            {
                new Array(favList.length).fill(favList).map((_, i) => {
                    return (<Space key={'fav-item-box-' + i} onMouseEnter={() => thumbnailMouseOverHandler(i)} onMouseLeave={() => thumbnailMouseOutHandler(i)}>
                        <div className={"fav-item fav-item-" + i}>
                            <div className={"fav-item-thumbnail theme-" + favList[i].config.shotTheme + " background-" + favList[i].config.shotBackground}>
                                <div className={"content"}>
                                    <div className={"content-text aspect-" + favList[i].config.shotAspect + " text-background-" + favList[i].config.shotTextBackground + " text-color-" + favList[i].config.shotTextColor}>
                                        <div className={"content-text-text"}>{favList[i].title}</div>
                                    </div>
                                </div>
                            </div>
                            <ButtonGroup>
                                <Button type="primary" key={'apply_' + favList[i].key} onClick={() => onFavoritesApply(favList[i].key)}>{t('menu.favorite.apply', 'Apply')}</Button>
                                <Button type="normal" key={'delete_' + favList[i].key} onClick={() => onFavoritesDelete(favList[i].key)}>{t('menu.favorite.delete', 'Delete')}</Button>
                            </ButtonGroup>
                        </div>
                    </Space>)
                }
                )
            }
        </div>
    </>);
}