const globalCodeSupport = [
  {
    label: 'Bash',
    key: 'bash'
  },
  {
    label: 'C#',
    key: 'csharp'
  },
  {
    label: 'C++',
    key: 'cpp'
  },
  {
    label: 'C',
    key: 'c'
  },
  {
    label: 'CSS',
    key: 'css'
  },
  {
    label: 'Dart',
    key: 'dart'
  },
  {
    label: 'GO',
    key: 'go'
  },
  {
    label: 'HTML,XML',
    key: 'html'
  },
  {
    label: 'JSON',
    key: 'json'
  },
  {
    label: 'Java',
    key: 'java'
  },
  {
    label: 'JavaScript',
    key: 'javascript'
  },
  {
    label: 'Kotlin',
    key: 'kotlin'
  },
  {
    label: 'Markdown',
    key: 'markdown'
  },
  {
    label: 'Nginx',
    key: 'nginx'
  },
  {
    label: 'ObjectiveC',
    key: 'objectivec'
  },
  {
    label: 'PHP',
    key: 'php'
  },
  {
    label: 'Python',
    key: 'python'
  },
  {
    label: 'SQL',
    key: 'sql'
  },
  {
    label: 'TypeScript',
    key: 'typescript'
  },
  {
    label: 'YAML',
    key: 'yaml'
  },
]

export default globalCodeSupport;