import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './BoxToolbar.scss';
import { useTranslation } from "react-i18next";

import global from '../lib/global';

export default function BoxToolbar({ handleToolbar, handleToolbarDownload }) {

  function gtag() { window.dataLayer.push(arguments); }
  const { t } = useTranslation();

  //const { MenuProps } = Dropdown;


  const handleToolbarPaste = (e) => {

    gtag('event', 'click', {
      'element_id': 'paste'
    });

    handleToolbar('paste');
  }

  const handleToolbarShuffle = (e) => {
    gtag('event', 'click', {
      'element_id': 'shuffle'
    });
    handleToolbar('shuffle');
  }

  const handleMenuClick = (e) => {
    gtag('event', 'click', {
      'element_id': e.key
    });
    handleToolbar(e.key);
  }

  const handleMainButtonClick = (index) => {
    gtag('event', 'click', {
      'element_id': 'auto'
    });
    handleToolbar('auto')
  }
  const handleDownload = (index) => {
    gtag('event', 'click', {
      'element_id': 'download'
    });
    handleToolbarDownload();
  }
  const handleDownloadClick = (e) => {
    gtag('event', 'click', {
      'element_id': e.key
    });
    handleToolbar(e.key);
  }
  const itemsCodeSupport = global.CodeSupport;
  const menuCodeSupport = {
    items: itemsCodeSupport,
    onClick: handleMenuClick,
  };

  const itemsDownloadFormat = global.DownloadFormat;
  const menuDownloadItems = {
    items: itemsDownloadFormat,
    onClick: handleDownloadClick,
  };

  // const handleButtonClick = (e) => {
  //   console.log('handleButtonClick ', e.target.outerText.toLowerCase());
  //   handleToolbar(e.target.outerText.toLowerCase())
  // };

  return (<div id={"box-content-toolbar"}>
    <Space>
      <Button type="primary" onClick={handleToolbarPaste}>{t('toolbar.paste', 'Paste')}</Button>
      <Dropdown.Button
        type="primary"
        icon={<DownOutlined />}
        menu={menuCodeSupport}
        onClick={() => handleMainButtonClick(1)}
      >
        {t('toolbar.auto', 'Auto')}
      </Dropdown.Button>
      {/* <Button type="primary" onClick={handleButtonClick}>Auto</Button>
      <Button type="default" onClick={handleButtonClick}>HTML</Button>
      <Button type="default" onClick={handleButtonClick}>JS</Button>
      <Button type="default" onClick={handleButtonClick}>CSS</Button>
      <Button type="default" onClick={handleButtonClick}>JSON</Button>
      <Button type="default" onClick={handleButtonClick}>SQL</Button> */}
      <Button id="toolbarShuffle" type="primary" onClick={handleToolbarShuffle}>{t('toolbar.shuffle', 'Shuffle')}</Button>
      {/* <Button type="primary" onClick={handleToolbarDownload} icon={<DownloadOutlined />}>{t('toolbar.download', 'Download')}</Button> */}

      {/* <Button type="primary" onClick={handleDownload}>{t('toolbar.download', 'Download')}</Button> */}
      <Dropdown.Button
        type="primary"
        icon={<DownOutlined />}
        menu={menuDownloadItems}
        onClick={() => handleDownload(1)}
      >
        {t('toolbar.download', 'Download')}
      </Dropdown.Button>
    </Space>
  </div>
  );
}
