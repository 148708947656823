
export default function shuffleConfig(dataConfig) {
    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            // Generate random number
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
    let newConfig = dataConfig;
    if (!!newConfig.shotTheme) {
        let newArray=shuffleArray(['dark', 'github-dark', 'github-dark-dimmed', 'github-light', 'night-owl', 'xcode', 'panda', 'chinese', 'chinese-ancient', 'chinese-zen']);
        newConfig.shotTheme = newArray[0];
    }
    if (!!newConfig.shotFont) {
        let newArray=shuffleArray(['tiny', 'small', 'normal', 'big', 'mega']);
        newConfig.shotFont = newArray[0];
    }
    if (!!newConfig.shotPadding) {
        let newArray=shuffleArray(['30', '60', '80', '100', '120']);
        newConfig.shotPadding = newArray[0];        
    }
    if (!!newConfig.shotBackground) {
        newConfig.shotBackground = ''+Math.floor(Math.random() * (90));
    }

    return newConfig;
}