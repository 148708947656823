const globalHistory = [
  {
    updateDate: '2024-10-09',
    updateContent: '✅ Add auto size mode - DevShots'
  },
  {
    updateDate: '2024-10-01',
    updateContent: '🇨🇳 Happy Nation Day'
  },
  {
    updateDate: '2024-09-29',
    updateContent: '🇨🇳 Add Chinese Ming Dynasty Font'
  },
  {
    updateDate: '2024-08-10',
    updateContent: '🪳 Updated to Html2canvas-pro'
  },
  {
    updateDate: '2024-06-11',
    updateContent: '⏩ Add JPEG image format download'
  },
  {
    updateDate: '2024-03-20',
    updateContent: '🍎 AppStore iPad Screenshot'
  },
  {
    updateDate: '2024-03-15',
    updateContent: '🍎 AppStore iPhone Screenshot'
  },
  {
    updateDate: '2024-03-07',
    updateContent: '📰 Add KingHwa Old Song Font'
  },
  {
    updateDate: '2024-03-06',
    updateContent: '📰 Add Old Newspaper Theme'
  },
  {
    updateDate: '2024-03-02',
    updateContent: '🀀 Add Chiense Peom Theme and 2 Chinese Fonts'
  },
  {
    updateDate: '2024-02-28',
    updateContent: '🗂 Optimize Favorites Function'
  },
  {
    updateDate: '2024-01-27',
    updateContent: '🚗 Fix Word Wrap Style'
  },
  {
    updateDate: '2023-11-30',
    updateContent: '🔟 Ten Code Font'
  },
  {
    updateDate: '2023-11-29',
    updateContent: '⌚️ Apple Watch,iPad Mockup'
  },
  {
    updateDate: '2023-11-26',
    updateContent: '📱 iPhone Mockup'
  },
  {
    updateDate: '2023-11-23',
    updateContent: '🗂 Favorites Function '
  },
  {
    updateDate: '2023-11-23',
    updateContent: '🇯🇵 2 Japanese Font '
  },
  {
    updateDate: '2023-11-19',
    updateContent: '🔠 Upto 10 Font Family'
  },
  {
    updateDate: '2023-11-18',
    updateContent: '🆕 New DevShots.app Domain 🎂'
  },
  {
    updateDate: '2023-11-18',
    updateContent: '🎨 Text Color and Background 🎂'
  },
  {
    updateDate: '2023-11-16',
    updateContent: '🇰🇷 Support Korean 🇨🇳🇬🇧🇯🇵🇰🇷'
  },
  {
    updateDate: '2023-11-14',
    updateContent: '🇯🇵 Support Japanese 🇨🇳🇬🇧🇯🇵'
  },
  {
    updateDate: '2023-11-13',
    updateContent: '🔠 Modify Font Face 👧🎂'
  },
  {
    updateDate: '2023-11-12',
    updateContent: '✅ DevShots Media Responsive'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '📱 Add iPhone15 Mockup'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '🌃 Image x Text Mode'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '🎲 Fun Shuffle Theme'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '📝 Support Markdown Format'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '🔍 Upscale Download Image'
  },
  {
    updateDate: '2023-11-11',
    updateContent: '🀄️ Add 4 Chinese Font Theme'
  },
]

export default globalHistory;