const globalShotDeviceData = [
  {
    key: 'iphonex',
    default: 'iPhoneX',
    frameWidth: 1280,
    frameHeight: 1280,
    contentWidth: 510,
    contentHeight: 1100,
    contentLeft: 385,
    contentTop: 90,
    contentRadius: 50,
    image: '/devices/iphonex.png',
    imageMockup: '/devices/iphonexmockup.png',
  },
  {
    key: 'iphonex_landscape',
    default: 'iPhoneX LandScape',
    frameWidth: 1280,
    frameHeight: 720,
    contentWidth: 1100,
    contentHeight: 510,
    contentLeft: 90,
    contentTop: 105,
    contentRadius: 50,
    image: '/devices/iphonexlandscape.png',
    imageMockup: '/devices/iphonexlandscapemockup.png',
  },
  {
    key: 'iphone15',
    default: 'iPhone15',
    frameWidth: 1280,
    frameHeight: 1280,
    contentWidth: 504,
    contentHeight: 1092,
    contentLeft: 388,
    contentTop: 94,
    contentRadius: 64,
    image: '/devices/iphone15.png',
    imageMockup: '/devices/iphone15mockup.png',
  },
  {
    key: 'iphone15_landscape',
    default: 'iPhone 15 LandScape',
    frameWidth: 1280,
    frameHeight: 720,
    contentWidth: 1092,
    contentHeight: 504,
    contentLeft: 94,
    contentTop: 108,
    contentRadius: 64,
    image: '/devices/iphone15landscape.png',
    imageMockup: '/devices/iphone15landscapemockup.png',
  },
  {
    key: 'ipad',
    default: 'iPad',
    frameWidth: 1200,
    frameHeight: 1200,
    contentWidth: 560,
    contentHeight: 854,
    contentLeft: 320,
    contentTop: 173,
    contentRadius: 16,
    image: '/devices/ipad.png',
    imageMockup: '/devices/ipad.png',
  },
  {
    key: 'ipad_landscape',
    default: 'iPad LandScape',
    frameWidth: 1200,
    frameHeight: 1200,
    contentWidth: 854,
    contentHeight: 560,
    contentLeft: 173,
    contentTop: 320,
    contentRadius: 16,
    image: '/devices/ipadlandscape.png',
    imageMockup: '/devices/ipadlandscape.png',
  },
  {
    key: 'apple_watch_black',
    default: 'Apple Watch Black',
    frameWidth: 1024,
    frameHeight: 1024,
    contentWidth: 450,
    contentHeight: 532,
    contentLeft: 287,
    contentTop: 246,
    contentRadius: 108,
    image: '/devices/applewatchblack.png',
    imageMockup: '/devices/applewatchblack.png',
  },
  {
    key: 'apple_watch_white',
    default: 'Apple Watch White',
    frameWidth: 1024,
    frameHeight: 1024,
    contentWidth: 450,
    contentHeight: 532,
    contentLeft: 287,
    contentTop: 246,
    contentRadius: 108,
    image: '/devices/applewatchwhite.png',
    imageMockup: '/devices/applewatchwhite.png',
  }
]
export default globalShotDeviceData;
