const globalDownloadFormat = [
  {
    label: 'JPEG',
    key: 'jpg'
  },
  {
    label: 'PNG',
    key: 'png'
  },
]

export default globalDownloadFormat;