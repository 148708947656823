import React from 'react';

import { Input, Typography, Space, Radio } from 'antd';
import {
  RadiusUpleftOutlined,
  BorderTopOutlined,
  RadiusUprightOutlined,
  RadiusBottomleftOutlined,
  BorderBottomOutlined,
  RadiusBottomrightOutlined,
} from '@ant-design/icons';

import { useTranslation } from "react-i18next";

export default function ModelOptions(shotConfig, handleShotConfig) {

  const { t } = useTranslation();
  const { Title } = Typography;

  const onChangeWatermarkStyle = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotWatermarkStyle = e.target.value;
    handleShotConfig(_shotConfig)
  };

  const onChangeWatermarkUserName = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotWatermarkUserName = e.target.value;
    handleShotConfig(_shotConfig)
  };
  const onChangeWatermarkUserID = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotWatermarkUserID = e.target.value;
    handleShotConfig(_shotConfig)
  };
  const onChangeWatermarkLocation = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotWatermarkLocation = e.target.value;
    handleShotConfig(_shotConfig)
  };

  const frameOptions = (<>
    <Title level={4}>{t('menu.option.watermark.style.title', 'Watermark Style')}</Title>
    <Radio.Group onChange={onChangeWatermarkStyle} value={shotConfig.shotWatermarkStyle}>
      <div>
        <Radio key='watermark0' value={'0'}>{t('menu.option.watermark.style.hide', 'Hide')}</Radio>
      </div>
      <div>
        <Radio key='watermark7' value={'7'}>{t('menu.option.watermark.style.user_name', 'User Name')}</Radio>
      </div>
      <div>
        <Radio key='watermark8' value={'8'}>{t('menu.option.watermark.style.user_id', 'User ID')}</Radio>
      </div>
      <div>
        <Radio key='watermark1' value={'1'}>{t('menu.option.watermark.style.small_logo', 'Small Logo')}</Radio>
      </div>
      <div>
        <Radio key='watermark2' value={'2'}>{t('menu.option.watermark.style.big_logo', 'Big Logo')}</Radio>
      </div>
      <div>
        <Radio key='watermark3' value={'3'}>{t('menu.option.watermark.style.logo_name', 'Logo+Name')}</Radio>
      </div>
      <div>
        <Radio key='watermark4' value={'4'}>{t('menu.option.watermark.style.logo_name_id', 'Logo+Name+ID')}</Radio>
      </div>
      <div>
        <Radio key='watermark5' value={'5'}>{t('menu.option.watermark.style.logo_bar_light', 'Logo Bar Light')}</Radio>
      </div>
      <div>
        <Radio key='watermark6' value={'6'}>{t('menu.option.watermark.style.logo_bar_dark', 'Logo Bar Dark')}</Radio>
      </div>
    </Radio.Group>
    <Title level={4}>{t('menu.option.watermark.content.title', 'Watermark Content')}</Title>
    <Space direction={"vertical"}>
      <Input type={"string"} maxLength={20} placeholder={t('menu.option.watermark.content.user_name_placeholder', 'Your User Name')} value={shotConfig.shotWatermarkUserName} onChange={onChangeWatermarkUserName} />
      <Input type={"string"} maxLength={20} placeholder={t('menu.option.watermark.content.user_id_placeholder', 'Your User Id')} value={shotConfig.shotWatermarkUserID} onChange={onChangeWatermarkUserID} />
    </Space>
    <Title level={4}>{t('menu.option.watermark.location.title', 'Watermark Location')}</Title>
    <Radio.Group onChange={onChangeWatermarkLocation} value={shotConfig.shotWatermarkLocation} optionType="button" id="RadioWatermark">
      <div>
        <Radio key='Watermark1' value={'1'}><RadiusUpleftOutlined style={{ fontSize: '16px' }} /></Radio>
        <Radio key='Watermark2' value={'2'}><BorderTopOutlined style={{ fontSize: '16px' }} /></Radio>
        <Radio key='Watermark3' value={'3'}><RadiusUprightOutlined style={{ fontSize: '16px' }} /></Radio>
      </div>
      <div>
        <Radio key='Watermark4' value={'4'}><RadiusBottomleftOutlined style={{ fontSize: '16px' }} /></Radio>
        <Radio key='Watermark5' value={'5'}><BorderBottomOutlined style={{ fontSize: '16px' }} /></Radio>
        <Radio key='Watermark6' value={'6'}><RadiusBottomrightOutlined style={{ fontSize: '16px' }} /></Radio>
      </div>
    </Radio.Group></>);


  return (frameOptions);
}